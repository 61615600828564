<template>
  <div class="property">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <!-- <el-form-item label="城市">
            <el-select v-model.trim="areaId" @change="areaChange" filterable placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option :label="area.areaName" :value="area.areaId" :key="area.areaId" v-for="area in areaList"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="streetId" @change="streetChange" filterable placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option :label="area.areaName" :value="area.areaId" :key="area.areaId" v-for="area in streetList"></el-option>
            </el-select>
          </el-form-item> -->
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_item')">
                <el-select
                  @change="getAssetsTypeList1"
                  v-model.trim="formInline.assetsTypeParentId"
                  filterable
                  placeholder="请选择"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="item.assetsTypeName"
                    :value="item.assetsTypeId"
                    v-for="item in assetsTypeList"
                    :key="item.assetsTypeId"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Item_model')">
                <el-select
                  v-model.trim="formInline.assetsTypeId"
                  filterable
                  placeholder="请选择"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="item.assetsTypeName"
                    :value="item.assetsTypeId"
                    v-for="item in assetsTypeList1"
                    :key="item.assetsTypeId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
              <el-button
                type="info"
                icon="el-icon-delete"
                @click="clearFrom()"
                :loading="loading"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70px"
            align="center"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import autoComplete from "@/mycomponents/myautocomplete";
export default {
  name: "propertyTotal",
  components: {
    // autoComplete
  },
  data() {
    return {
      tenantList: [], // 商户数据
      areaList: [], // 城市列表
      streetList: [], // 区街道
      streetId: "",
      areaId: "",
      assetsTypeList: [],
      assetsTypeList1: [],
      page: 0,
      pageSize: 10,
      total: 0,
      tableCols: [
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "assetsTypeParentName",
          label: this.$t("list.Type_of_item"),
          width: "",
        },
        {
          prop: "assetsTypeName",
          label: this.$t("list.Item_model"),
          width: "",
        },
        {
          prop: "atUse",
          label: this.$t("list.Already_received"),
          width: "",
        },
        {
          prop: "idle",
          label: this.$t("list.idle"),
          width: "",
        },
        {
          prop: "discard",
          label: this.$t("list.Abandoned"),
          width: "",
        },
        {
          prop: "subtotal",
          label: this.$t("list.subtotal"),
          width: "",
        },
      ],
      loading: false,
      tableData: [],
      formInline: {
        //  areaId: '',
        assetsTypeParentId: "",
        assetsTypeId: "",
        operationId: "",
      },
    };
  },
  methods: {
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    areaChange(areaId) {
      this.streetList = [];
      this.streetId = "";
      if (!areaId) return;
      this.getStreet(areaId);
    },
    streetChange(streetId) {
      //
    },
    // 获取城市区域
    getAreaList() {
      let url = "/acb/2.0/systems/loginUser/initAreaList";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          if (res.value.depth == 3) {
            this.areaList = res.value.areaList;
          } else {
            this.streetList = res.value.areaList;
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 根据城市获取区
    getStreet(areaId) {
      let url = "/acb/2.0/systems/loginUser/getChildrenArea";
      this.$axios
        .get(url, {
          data: {
            areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.streetList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 获取物品类型
    getAssetsTypeList() {
      this.$axios
        .get("/acb/2.0/assetsType/allList", {
          data: {
            type: 0,
          },
        })
        .then((res) => {
          this.assetsTypeList = res.value.list;
        });
    },
    getAssetsTypeList1(id) {
      this.$axios
        .get("/acb/2.0/assetsType/allList", {
          data: {
            assetsTypeId1: id,
            type: 1,
          },
        })
        .then((res) => {
          this.assetsTypeList1 = res.value.list;
        });
    },
    searchData() {
      // this.formInline.areaId = this.streetId ? this.streetId : this.areaId;
      this.$axios
        .get("/acb/2.0/assetsStatistics/assetsStatisticsList", {
          data: {
            ...this.formInline,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.value) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          } else {
            this.tableData = [];
            this.total = 0;
          }
        });
    },
    clearFrom() {
      this.formInline = {
        assetsTypeParentId: "",
        assetsTypeId: "",
        operationId: "",
      };
    },
    handleCommand(cmd, data) {
      if (cmd === "a") {
        this.view();
      }
    },
    view() {
      this.$router.push({
        path: "informationDetails",
      });
    },
    success() {
      this.searchData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
  },
  mounted() {
    this.searchData();
    this.getAssetsTypeList();
  },
  created() {
    this.getAreaList();
    this.getTenantList();
  },
};
</script>
<style lang="stylus" scoped>
.property {
  .breadcrumb {
    height: 35px;
  }

  .content {
    overflow: hidden;

    .searchWrapper {
      overflow: hidden;
      // background: #EFF2F7;
    }

    .pagerWrapper {
      text-align: right;
      margin-top: 18px;
      font-size: 12px;
    }
  }
}
</style>
